import axios from "./axios.js"
// 通用api，包括登录等
const apiList = {
  // 获取商品列表
  getPageSpuList: "/pms/spuInfo/list",
  // 添加商品
  addGoods: '/pms/spuInfo/create',
  // 编辑商品
  editGoods: '/pms/spuInfo/edit',
  // 获取商品详情
  getGoodInfo: '/pms/spuInfo',
  // 删除商品
  removeGoods: '/pms/spuInfo/remove',
  // 上下架
  upStatusSpuInfo: '/pms/spuInfo/upStatusSpuInfo',
  // 轮播图列表
  homeList: '/homeAdv/list',
  // 新增轮播图
  addHomeAdv: '/homeAdv/create',
  // 编辑轮播图
  editHomeAdv: '/homeAdv/update',
  // 详情
  homeAdvInfo: '/homeAdv/get',
  // 删除轮播图
  delHomeAdv: '/homeAdv/remove',
  // 会员列表
  memberList: '/ums/member/list',
  // 站点列表
  siteList: '/ums/site/list',
  // 站点审核
  siteAudit: '/ums/site/audit',
  // 站点编辑
  editSite: '/ums/site/edit',
  // 站点收益
  earningsInfoList: '/ums/earningsInfo/pageList',
  // 订单列表
  orderList: '/oms/order/pageList',
  // 分类列表
  catalogList: '/base/catalog/list',
  // 获取分类
  getCatalog: '/base/catalog/get',
  // 添加分类
  addCatalog: '/base/catalog/create',
  // 添加分类
  editCatalog: '/base/catalog/update',
  // 删除分类
  delCatalog: '/base/catalog/remove',
  // 获取商品多规格
  goodAttrInfo: '/base/attrInfo/values',
  // 添加属性
  addAttrValue: '/base/attrValue/create',
  // 删除属性
  delAttrValue: '/base/attrValue/remove',
  // 获取会员结构关系树
  getTreeData: '/ums/member/viewTree',
  // 站点销售数据
  siteStatistics: '/oms/order/siteStatistics',
  // 提现列表
  extractLogList: '/ums/extractLog/pageList',
  //提现详情
  extractLogInfo: '/ums/extractLog/get',
  // 售后列表
  afterSaleList: '/oms/orderReturn/pageList',
  // 售后审批
  afterApprove: '/oms/orderReturn/approve',
  // 余额流水
  userMoneyInfo: '/ums/moneyInfo/pageList',
  // 销售统计
  salesStatistics: '/oms/order/salesStatistics',
  // 会员统计
  memberStatistics: '/ums/member/memberStatistics',
  // 佣金管理统计
  memberSaleList: '/ums/earningsInfo/memberSaleList',
  // 优惠卷列表
  couponList: '/sms/coupon/pageList',
  // 删除优惠卷
  couponRemove: '/sms/coupon/remove',
  // 分类列表
  getCatalogList: '/base/catalog/catalogAll',
  // 获取商品
  getSpuList: '/pms/spuInfo/spuAll',
  // 添加优惠卷
  createCoupon: '/sms/coupon/create',
  // 编辑优惠卷
  updateCoupon: '/sms/coupon/update',
  // 领取记录
  historyList: '/sms/couponHistory/pageList',
  // 获取站点
  getSiteList: '/ums/site/siteList',
  // 站点待结算订单
  siteSettleOrders: '/oms/order/settle',
  // 结算
  addSettle: '/ums/settle/create',
  // 结束记录
  settleInfoList: '/ums/settle/pageList',
  // 会员设置
  editMember: '/ums/member/edit',

}
export default {
  apiList,

  getPageSpuList (data = {}) {
    return axios({
      url: apiList.getPageSpuList,
      method: "post",
      data,
    })
  },
  addGoods (data = {}) {
    return axios({
      url: apiList.addGoods,
      method: "post",
      data,
    })
  },
  editGoods (data = {}) {
    return axios({
      url: apiList.editGoods,
      method: "post",
      data,
    })
  },
  getGoodInfo (id) {
    return axios({
      url: apiList.getGoodInfo + '/' + id,
      method: 'get',
    })
  },
  removeGoods (data) {
    return axios({
      url: apiList.removeGoods,
      method: 'get',
      data
    })
  },
  upStatusSpuInfo (data = {}) {
    return axios({
      url: apiList.upStatusSpuInfo,
      method: "post",
      data,
    })
  },
  homeList (data = {}) {
    return axios({
      url: apiList.homeList,
      method: 'post',
      data
    })
  },
  addHomeAdv (data = {}) {
    return axios({
      url: apiList.addHomeAdv,
      method: 'post',
      data
    })
  },
  editHomeAdv (data = {}) {
    return axios({
      url: apiList.editHomeAdv,
      method: 'post',
      data
    })
  },
  homeAdvInfo (id) {
    return axios({
      url: apiList.homeAdvInfo + '/' + id,
      method: 'get',
    })
  },
  delHomeAdv (data = {}) {
    return axios({
      url: apiList.delHomeAdv,
      method: 'get',
      data
    })
  },
  memberList (data = {}) {
    return axios({
      url: apiList.memberList,
      method: 'post',
      data
    })
  },
  siteList (data = {}) {
    return axios({
      url: apiList.siteList,
      method: 'post',
      data
    })
  },
  siteAudit (data = {}) {
    return axios({
      url: apiList.siteAudit,
      method: 'get',
      data
    })
  },
  editSite (data = {}) {
    return axios({
      url: apiList.editSite,
      method: 'post',
      data
    })
  },
  earningsInfoList (data = {}) {
    return axios({
      url: apiList.earningsInfoList,
      method: 'post',
      data
    })
  },
  orderList (data = {}) {
    return axios({
      url: apiList.orderList,
      method: 'post',
      data
    })
  },
  catalogList (data = {}) {
    return axios({
      url: apiList.catalogList,
      method: 'post',
      data
    })
  },
  getCatalog (id) {
    return axios({
      url: apiList.getCatalog + '/' + id,
      method: 'get',
    })
  },
  addCatalog (data = {}) {
    return axios({
      url: apiList.addCatalog,
      method: 'post',
      data
    })
  },
  editCatalog (data = {}) {
    return axios({
      url: apiList.editCatalog,
      method: 'post',
      data
    })
  },
  delCatalog (data = {}) {
    return axios({
      url: apiList.delCatalog,
      method: 'get',
      data
    })
  },
  goodAttrInfo (data = {}) {
    return axios({
      url: apiList.goodAttrInfo,
      method: 'get',
      data
    })
  },
  addAttrValue (data = {}) {
    return axios({
      url: apiList.addAttrValue,
      method: 'post',
      data
    })
  },
  delAttrValue (data = {}) {
    return axios({
      url: apiList.delAttrValue,
      method: 'get',
      data
    })
  },
  getTreeData (data = {}) {
    return axios({
      url: apiList.getTreeData,
      method: 'get',
      data
    })
  },
  siteStatistics (data = {}) {
    return axios({
      url: apiList.siteStatistics,
      method: 'get',
      data
    })
  },
  extractLogList (data = {}) {
    return axios({
      url: apiList.extractLogList,
      method: 'get',
      data
    })
  },
  extractLogInfo (data = {}) {
    return axios({
      url: apiList.extractLogInfo,
      method: 'get',
      data
    })
  },
  afterSaleList (data = {}) {
    return axios({
      url: apiList.afterSaleList,
      method: 'post',
      data
    })
  },
  afterApprove (data = {}) {
    return axios({
      url: apiList.afterApprove,
      method: 'get',
      data
    })
  },
  userMoneyInfo (data = {}) {
    return axios({
      url: apiList.userMoneyInfo,
      method: 'post',
      data
    })
  },
  salesStatistics (data = {}) {
    return axios({
      url: apiList.salesStatistics,
      method: 'post',
      data
    })
  },
  memberStatistics (data = {}) {
    return axios({
      url: apiList.memberStatistics,
      method: 'post',
      data
    })
  },
  memberSaleList (data = {}) {
    return axios({
      url: apiList.memberSaleList,
      method: 'post',
      data
    })
  },
  couponList (data = {}) {
    return axios({
      url: apiList.couponList,
      method: 'post',
      data
    })
  },
  couponRemove (data = {}) {
    return axios({
      url: apiList.couponRemove,
      method: 'get',
      data
    })
  },
  getCatalogList (data = {}) {
    return axios({
      url: apiList.getCatalogList,
      method: 'get',
      data
    })
  },
  getSpuList (data = {}) {
    return axios({
      url: apiList.getSpuList,
      method: 'get',
      data
    })
  },
  createCoupon (data = {}) {
    return axios({
      url: apiList.createCoupon,
      method: 'post',
      data
    })
  },
  updateCoupon (data = {}) {
    return axios({
      url: apiList.updateCoupon,
      method: 'post',
      data
    })
  },
  historyList (data = {}) {
    return axios({
      url: apiList.historyList,
      method: 'post',
      data
    })
  },
  getSiteList (data = {}) {
    return axios({
      url: apiList.getSiteList,
      method: 'get',
      data
    })
  },
  siteSettleOrders (data = {}) {
    return axios({
      url: apiList.siteSettleOrders,
      method: 'get',
      data
    })
  },
  addSettle (data = {}) {
    return axios({
      url: apiList.addSettle,
      method: 'post',
      data
    })
  },
  settleInfoList (data = {}) {
    return axios({
      url: apiList.settleInfoList,
      method: 'post',
      data
    })
  },
  editMember (data = {}) {
    return axios({
      url: apiList.editMember,
      method: 'post',
      data
    })
  },

}
